.sectionsBoxes {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    max-width: 35%;
}

.sectionBox {
    border-radius: 5px;
    border: solid 1px #ddd;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    min-width: 180px;
    height: 60px;
    margin: 8px 5px;
}

.sectionBox__avatar {
    width: 40px;
    max-height: 40px;
}

.sectionBox__name {
    margin: 0 10px;

    h3 {
        font-size: 12px;
        font-weight: bold;
        line-height: 1.88;
        text-align: right;
        margin: 0;
        color: #707070;
    }
}

@media (max-width: 759px) {
    .section__ads {
        margin: 10px 0;
    }

    .sectionsBoxes {
        max-width: 100%;
        margin: auto;
        
        a {
            width: 48%;
            margin: 1%;
            
            .sectionBox {
                min-width: auto;
                margin: 0;
            }
        }
    }
}

@media (min-width: 759px) and (max-width: 980px) {
    .sectionsBoxes {
        max-width: 70%;
        margin: auto;
        margin: auto;
    }
}

@media (min-width: 981px) and (max-width: 1120px) {
    .sectionsBoxes {
        max-width: 45%;
    }
}