.searchSuggestions {
    position: absolute;
    margin-top: 46px;
    z-index: 1000;
    max-height: 400px;
    background: #fff;
    width: 100%;
    border: 1px solid #cbcbcb;
    overflow: auto;

    ul {
        float: left;
        width: 100%;
        list-style: none;
        margin: 0;
        padding-bottom: 3px;
        padding-left: 0;

        li {    
            float: left;
            width: 100%;
            margin: 5px 0;

            a {
                display: inline-block;
                width: 100%;
                padding: 0 5px;
                font-size: 13px;
                font-weight: 100;
                color: $blue;
            
                &:before {
                    font-family: FontAwesome;
                    content: "\f105";
                    font-weight: 900;
                    margin: 5px;
                }
            }

            &.separator {
                width: 100%;
                display: block;
                float: left;
                border-bottom: 1px solid #cbcbcb;
                background-color: #f7f7f7;
                margin-top: 0;
                color: #7e7e7e;
                font-weight: bold;
                font-size: 10px;
                text-transform: uppercase;
                letter-spacing: 1px;
                padding-left: 7px!important;
                line-height: 2.5;
            }
        }
    }
}

