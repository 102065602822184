.section__wrapper {
    width: 100%;
    border-radius: 20px;
    padding: 20px 40px;
    box-shadow: 6px 6px 20px 0 rgba(0, 0, 0, 0.18);
    border: solid 1px rgba(0, 0, 0, 0.18);
    background-color: #ffffff;
    margin: 30px 0;

    .brands-list {
        padding-right: 225px;
        .badge-pill{
            padding: 8px 0;
            margin: 4px 4px;
            width: 80px;
            a{
                color: $white;
                font-size: 12px
            }
        }

        &.rtl {
            text-align: right;
            padding-left: 225px;
            padding-right: unset;

        }
    }

}
.section__staticPrices {
    display: flex;
    justify-content: space-around;
}

.see-more__mobile {
    background-color: $dark-orange;
    text-align: center;
    color: $white;
    padding: 4px 8px;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 16px;
    width: 50%;
    font-weight: 900;
}

.section__staticPrices__image {
    flex-grow: 2;
    max-width: 800px;
    height: 300px;
    display: flex;
    align-items: center;;
    img {
        max-height: 100%;
    }

    a {
        display: inline-block;
        align-self: flex-end;
        background-color: $dark-orange;
        color: $white;
        padding: 6px 16px;
        margin:0 0 40px 40px;
        border-radius: 5px;
        font-weight: 900;
        font-size: 16px;
        text-transform: uppercase;
    }
}

.section__staticPrices__prices {
    flex-grow: 1;
}

.section__staticPrices__header {
    border-bottom: 1px solid #ddd;


    h2 {
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.05;
        letter-spacing: normal;
        color: #393939;
    }
} 

.section__staticPrices__prices ul {
    list-style-type: none;
    margin: 0;
    max-width: 230px;

    li {
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.05;
        padding: 5px 0;
        letter-spacing: normal;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;

        span{
            //currency
            color: $blue;
            font-weight: 700;
        }

        a {
            color: #707070;

            &:hover {
                color: darken(#707070, 10%);
            }
        }
    }

    li:not(:last-child) {
        border-bottom: 1px solid #ddd;
    }
}

.price-categories {
    margin: 0 8px;
    font-size: 12px;
}

@media (max-width: 786px) {
    .brands-list {
        padding-right: unset !important;
        padding-left: unset !important;
    }
    .section__staticPrices {
        flex-direction: column;
        padding: 10px;
    }

    .section__staticPrices__header h2 {
        margin: 0;
    }

    .section__staticPrices__image {
        max-width: 200px;
        height: auto;
        margin: auto;
        flex-direction: column;
        a {
            // see_more button
            margin:unset;
            align-self: center;
        }
    }

    .section__staticPrices__prices ul {
        display: flex;
        flex-wrap: wrap;
        margin: 10px 0 0 0;
        justify-content: space-around;
        align-items: center;
        max-width: 100%;
    }

    .section__staticPrices__prices ul { 
    
        li {
            width: 130px;
            border-radius: 16px;
            border: solid 1px #707070;
            border-bottom: solid 1px #707070 !important;
            text-align: center;
            font-size: 12px;
            margin: 4px;
            justify-content: space-around;
        }
    }
}

@media (min-width: 787px) and (max-width: 980px) {
    .section__staticPrices {
        flex-direction: column;
    }
    .section__staticPrices__prices ul {
        max-width: 100%;
    }
}