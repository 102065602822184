.loader {
    border: 3px solid #eee;
    -webkit-animation: spin 1s linear infinite; 
    animation: spin 1s linear infinite; 
    border-top: 3px solid $blue;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 10px auto;
}

.spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  img {
    -webkit-filter: contrast(.3%);
    filter: contrast(0.3%);
    -webkit-animation: pulse 3s linear infinite; 
    animation: pulse 3s linear infinite; 
  }
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.floating-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100000;
    background: #fff;


    img {
      -webkit-filter: contrast(.3%);
      filter: contrast(0.3%);
      -webkit-animation: pulse 3s linear infinite; 
      animation: pulse 3s linear infinite; 
    }
  }

@-webkit-keyframes pulse {
  0% {
      -webkit-transform: scale(1);
      transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  100% {
      -webkit-transform: scale(1);
    transform: scale(1);
  }
}
