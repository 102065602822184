.model-images {
    max-width: 400px;
}

.model-images__slider-wrapper {
  margin-bottom: 30px;
}

.model-images__slider-wrapper__selected {
    position: relative;
    width: 400px;
    height: 350px;
    border-radius: 10px;
    border: 1px solid #dedddd
}

.model-images__slider-wrapper__selected__image {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;

    img {
        max-height: 100%;
    }
}

.model-images__slider-wrapper__selected__actions {
    height: 50px;
   display: flex;
   justify-content: space-around;
   align-items: center;
   border-top: 1px solid #dedddd;
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;
}

.model-images__slider-wrapper__selected__actions__push_to_favorites,
.model-images__slider-wrapper__selected__actions__share {
    height: 100%;
    flex: 1;
    font-size: 14px;
    line-height: 1.88;
    color: #707070;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    cursor: pointer;
}

.model-images__slider-wrapper__selected__actions__push_to_favorites i,
.model-images__slider-wrapper__selected__actions__share span {
    margin: 0 8px;
    font-size: 18px;
    cursor: pointer;
}

.model-images__slider-wrapper__selected__actions__share  {
    border-right: 1px solid #dedddd;
    display: flex;
    justify-content: center;
    align-items: center;
}

.model-images__slider-wrapper__selected__actions__share .SocialMediaShareButton {
    margin: 0 3px !important;
    border-radius: 3px !important;
    cursor: pointer;
}


.model-images__slider-wrapper__thumbs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 20px;
}

.model-images__slider-wrapper__thumbs__thumb {
    width: 95px;
    height: 95px;
    border: 1px solid #dedddd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 5px;

    img {
        max-height: 100%;
    }


    &.selected {
        border: 1px solid $blue;
    }
}

.model-images__slider-wrapper__thumbs__thumb:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 5px;
}

.brand-category {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 0;


    span {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.67;
        color: $dark-blue;

        a {
            color: $red !important;
            margin: 0 5px;

        }
    }
}

@media (max-width: 800px) {
    .model-images__slider-wrapper__thumbs__thumb:not(:last-child) {
        margin-bottom: 0 !important; 
    }
}