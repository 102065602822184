.category-mobile-actions {
    display: none;
}

.category-mobile-actions__title {
    display: flex;
    align-items: center;

    span {
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: right;
        color: #707070;
        margin: 0 4px;
    }

    small {
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.9;
        letter-spacing: normal;
        text-align: right;
        color: #707070;
        margin: 0 10px;
    }
}

.category-mobile-actions__options {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    border: solid 1px #ddd;
    background-color: #ffffff;
}

.category-mobile-actions__options__filters {
    flex-grow: 5;
    text-align: center;
    border-right: solid 1px #ddd;

    p {
        margin: 0;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        padding: 5px 0;
        line-height: 1.9;
        letter-spacing: normal;
        color: #707070;
        padding: 3px 0;
    }

    i {
        margin: 0 5px;
        color: #707070;
    }
}

.category-mobile-actions__options__sort {
    flex-grow: 5;
    text-align: center;
    border-right: solid 1px #ddd;

    p {
        margin: 0;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        padding: 3px 0;
        line-height: 1.9;
        letter-spacing: normal;
        color: #707070;
    }

    img {
        width: 14px;
        margin: 0 5px;
    }
}

.category-mobile-actions__options__layoutView {
    flex-grow: 1;
    text-align: center;
    padding: 3px 0;

    i {
        color: #707070;
    }
}

@media (max-width: 800px) {
    .category-mobile-actions {
        display: block;
    }
}