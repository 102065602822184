.online-store {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 350px;
    padding: 8px 0;
    border-top: 1px solid #dedddd;

    &.h-60 {
        height: 60px;
    }

    &.best-price {
        border-top: 0;
        border-bottom: 1px solid #dedddd;
        p {
            font-size: 25px;
            line-height: 2.57;
            text-align: center;
            color: $dark-orange;
            text-transform: uppercase;
            margin: 0;
            font-weight: 700;
        }

    }
}

.online-store__information {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
}

.online-store__information__avatar {
    max-width: 100px;
    width: 100px;
    display: flex;
    height: 40px;
    max-height: 40px;
    justify-content: center;
    align-items: center;


    p {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #707070;
    }

    &.biggerAvatar {
        height: 80px;
        max-height: 80px;
    }
}

.online-store__information__avatar img {
    max-height: 100%;
}

.online-store__information__price {
    color: $blue;
    margin: 0 25px;
    font-style: normal;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: normal;

    &.new {
        color: #36aa14;
    }

    &.like {
        color: #b11414;
    }

    &.used {
        color: #7b7b7b;
    }

    .value {
        font-size: 16px;
    }

    .currency {
        font-size: 12px;
        margin: 0 4px;
    }

    &.big-size {
     
        .value {
            font-size: 20px;
        }
    
        .currency {
            font-size: 16px;
            margin: 0 4px;
        }

    }
}

.online-store__redirect {
    width: 120px;
    height: 25px;
    border-radius: 10px;
    background-color: $blue;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: darken($blue, 5%);
    }

    &.orange {
        background-color: $dark-orange;

        &:hover {
            background-color: darken($dark-orange, 5%);
        }
    }

    a {
        color: $white !important;
        font-size: 14px;
        padding: 0 15px;
        font-weight: normal;
    }

    &.itemsCount {
        border: solid 1px #dedddd;
        background-color: #ffffff;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.88;
        letter-spacing: normal;
        color: #707070;     
        cursor: pointer; 

        &.dark-bk {
            background-color: #e4e4e4;
            color: #707070;
        }

        i {
            margin: 0 10px
        }
    }
}