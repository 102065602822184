.subModels__lowestPricesItems {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: $white;
    background: linear-gradient(0deg, rgba(255,255,255,0.8911939775910365) 20%, rgba(255, 255, 255, 0.66) 100%);
    opacity: 0.9;
    display: none;
}

.subModels__lowestPricesItems.mobile {
    display: none;
}

.subModels__lowestPricesItems__item {
    display: flex;
    justify-content: space-around;
    flex-direction: row-reverse;
    align-items: center;
    border-bottom: 1px solid #eae9e9;
    padding: 7px 0;
}

.item-badge {
  background-image: linear-gradient(310deg,#7928ca,#ff0080);
  padding: 5px 12px;
  cursor: pointer;
}

.subModels__lowestPricesItems__item .item__store {
    .store {
        align-items: center;

        .item__store__img {
            width: 50px;
            height: 30px;
    
            img {
                max-height: 100%;
                width: 100%;
            }
        }
    
        span {
            font-size: 12px;
            line-height: 1.79;
            margin-right: 10px;
            color: #707070;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}

.subModels__lowestPricesItems__item .item__link {
    font-size: 20px;
    color: #0b88ee;
}


.subModel__details__price--flyingPrice {
    margin: 10px 0 0 0;

    .currency, .value {
        line-height: 1.13;
        margin: 0;
    }

    .value {
        color: $blue;
        font-size: 18px;
        font-weight: bold;
    }

    .currency {
        font-size: 12px;
        margin: 0 3px;
        color: rgb(126, 133, 155);
    }
}

.seeMore {
    padding: 10px;
    text-align: left;
    position: absolute;
    bottom: 0;

    p {
        margin: 0;
        font-size: 12px;
        line-height: 1.56;
        color: #d41313;
    }
}


@media (max-width: 768px) {
    .subModel {
        height: 350px;
    }

    .subModels__lowestPricesItems {
        position: initial;
        display: none !important;
        opacity: 1;
    }
    
    .subModels__lowestPricesItems__item {
        padding: 10px 0;


        a {
            display: flex;
            flex-grow: 1;
            justify-content: space-between;
        }

        .item__store {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1;
            padding: 0 5px;
        }

        .item__link {
            display: none;
        }
    }

    .subModels__lowestPricesItems__item .item__store .store .item__store__img {
        width: 50px;
        height: 15px;

        img {
            margin: auto;
            max-width: 100%;
            height: 100%;
        }
    }

    .subModel__details__price--flyingPrice {
        margin: 0;
    }
    .subModels__lowestPricesItems__item .item__store .store span {
        margin: 0 3px;
        font-size: 10px;
    }

    .subModel__details__price--flyingPrice .value {
        font-size: 12px;
    }

    .subModel__details__price--flyingPrice .currency {
        font-size: 10px;
    }

    .subModel__details__price {
        display: none;
    }

    .subModel__details {
        padding: 5px;
    }
    .seeMore {
        display: none;
    }

    .subModels__lowestPricesItems.mobile {
        display: block !important;
        
        .subModels__lowestPricesItems__item {
            border: 0;

            a {
                align-items: center;
                justify-content: flex-start;
            }

            .item__store .store {
                margin-right: 0;
            }

            .item__store .store span {
                display: none;
            }

            .item__store .store .item__store__img {
                width: 80px;
                height: 25px;

                img {
                    width: auto;
                    height: auto;
                }
            }
        }
    }

    .subModel__details__price--flyingPrice .value {
       font-size: 14px;
    }

    .subModel__details__price--flyingPrice .currency {
        color: $blue;
        margin: 0 4px;
    }

    .store-details {
      padding: 0 !important;
    }

    .gallery-images--smaller {
      width: 100% !important;

      .images {
        width: 230px;
        margin: auto;
      }
    }

    .store-bottom {
      .share, 
      .address {
        margin: 0 !important;
      }
    }
}

.subModel__details__actions{

    &__btn{
        width: 47%;
    }
}

.gallery-images--smaller {

  .gallery-images__selected {
    width: 230px;
    height: 230px;
    margin: auto;
  }

  .model-images__slider-wrapper__thumbs__thumb {
    width: 40px;
    height: 40px;

    &:last-child {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}

.social-pages {
  text-align: center;
  margin-left: 10px;
  font-size: 16px;
  background-image: linear-gradient(310deg, #7928ca, #ff0080);
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;

  &:hover {
    color: white;
  }

  i:hover { 
    color: white
  }
}