.store-options{
    display: flex;
    align-items: flex-end;
    //margin-bottom: 5px;

    .store-options__flex{
        display: flex;
        justify-content: space-evenly;
        gap: 12px;

        img{
            height: 30px;
            margin: 0 auto;
        }
    }

    .store-option{
        display: grid;
    }
}