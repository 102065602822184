.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background: rgba(107, 105, 105, 0.75) !important;
}

.ReactModal__Content.ReactModal__Content--after-open {
  width: 70% !important;
  margin: auto !important;
  min-height: 300px !important;
  height: 500px !important;
  border-radius: 10px !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45) !important;
  border: solid 20px rgba(255, 255, 255, 0.36) !important;
  padding: 0 20px !important;
  overflow: visible !important;

  @media(max-width: 700px) {
    padding: 0 !important;
  }
}

.form-fields {
  max-height: 350px;
  overflow: auto;
}

.custom-form-fields {
  max-height: 300px;
  overflow: auto;
}

.new-product{
  display: flex;
  justify-content: end;
}

.flex-form{
  height: 100%;

  form{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}