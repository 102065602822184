.widget {
    border: 1px solid #ddd;
    border-bottom: none;
    margin: 0;
    background-color: #fff;

    &:last-child {
        border-bottom: 1px solid #ddd;
    }
}

.widget__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid#dddd;
    padding: 10px;
}

.widget__header__title {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.83;
    letter-spacing: normal;
    color: #707070;
    margin: 0;
}

.widget__header__switcher {
    color: $blue;
    cursor: pointer;
    font-size: 18px;
}

.widget__body {
    padding: 10px;
}

.widget__items {
    margin: 0;
    padding: 0;   
}

.widget__item {
    list-style: none;
    display: block;
    overflow: hidden;
    white-space: nowrap;

    p {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
    }
}

.widget__item input {
    visibility: hidden;
    opacity: 0;
    display: inline-block;
    vertical-align: middle;
    width: 0;
}

.widget__item label {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #7e7e7e;
}

input[type=checkbox]~label, input[type=radio]~label {
    position: relative;
    padding-left: 24px;
    cursor: pointer;
}

input[type=checkbox]:checked~label::before {
    content: "\f00c";
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    color: $blue;
    font-size: 16px;
}

input[type=checkbox]~label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    border: 1px solid #e5e5e5;
    height: 15px;
    width: 15px;
    margin-top: -8px;
    border-radius: 3px;
    line-height: .9;
    font-size: 28px;
}

.rangeslider-horizontal .rangeslider__fill {
    background-color: $blue !important;
}

.rangeslider-horizontal .rangeslider__handle {
    width: 20px;
    height: 20px;

    &:focus {
        outline: none;
    }
}

.rangeslider-horizontal .rangeslider__handle:after {
    width: 10px;
    height: 10px;
    top: 4px;
    left: 4px;
}


.readMore {
    font-size: 12px;
    font-weight: normal;
    color: rgb(56, 102, 223);
    text-align: left;
    cursor: pointer;
    margin: 0;

    i {
        margin: 0 5px;
    }
}