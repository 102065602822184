.sortBy {
    position: fixed;
    top: 50px;
    right: 0;
    left: 0;
    bottom: 0;
    background: #fff;
    z-index: 1000;
}

.sortBy__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    border-bottom: 1px solid #ddd;

    span {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: right;
        color: #707070;
    }

    button {
        border: 0;
        background: 0;
        font-size: 14px;
        font-weight: bold;
        color: #707070;
    }
}

.sortBy__options {
    padding: 5px 15px;

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;

        p {
            margin: 0;
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.86;
            letter-spacing: normal;
            text-align: right;
            color: #707070;
        }

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            border: 2px solid #dddd;

            &.active {
                background: $blue;
                border: 2px solid $blue;

                i {
                    color: $white;
                    font-size: 10px;
                }
            }
        }
    }
}