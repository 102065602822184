@import url('https://fonts.googleapis.com/css?family=Cairo:400,600,700,900&display=swap&subset=arabic');
@import url('https://fontlibrary.org/face/droid-arabic-naskh');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Cairo', sans-serif;
    font-weight: 900;
    font-size: 14px;
    line-height: 24px;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #7e7e7e;
    font-weight: 400;
}

.width-102 {
    width: 102%;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #e8e8e8;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.overflow-wrapper {
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: 1px !important;
    }

    &::-webkit-scrollbar-track {
        background: #e8e8e8;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
    }
}

.main-theme {
    background-color: $blue;
}

#wrapper {
    margin: 0 auto;
    padding: 0 10px;
    max-width: 1200px;
}

img {
    max-width: 100%;
}

.single-input {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 20px;

    label {
        margin-bottom: 8px;
        display: block;
        color: $dark-gray;
    }

    select {
        background: transparent !important;
    }

    input, select {
        border-radius: 3px;
        border: 1px solid $off-gray;
        width: 100%;
        padding: 10px;
    }

    button {
        color: $white;
        padding: 11px 58px;
        font-size: 14px;
        line-height: 14px;
        border-radius: 2px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: inline-block;
        border: solid 2px transparent;
        -webkit-font-smoothing: antialiased;
        margin: 0 20px 30px 0;
        font-weight: 500;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        white-space: nowrap;
        background-image: none;
        -webkit-transition: all .15s;
        transition: all .15s;
        -webkit-box-shadow: 0 1px 6px rgba(57,73,76,0.35);
        box-shadow: 0 1px 6px rgba(57,73,76,0.35);

        a {
            display: block;
        }

        &:hover {
            background: darken($blue, 10%);
        }
    }
}

.products h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    padding: 10px 0;
}

.products--bordered {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #cccc
}

.red {
    background: #fb4949 !important;
}

.blue {
    background: $blue !important;
}

.darkBlue {
    background: #5058a4 !important;
}

.green {
    background: #2ea42a !important;
}

.pt-30 {
    padding-top: 30px;
}

.pb-30 {
    padding-bottom: 30px;
}

.ptb-30 {
    padding: 30px 0;
}

.ptb-20 {
    padding: 20px 0;
}

.mt-30 {
    margin-top: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mtb-30 {
    margin: 30px 0;
}

.mt-20 {
    margin-top: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mtb-20 {
    margin: 20px 0;
}

.mt-60 {
    margin-top: 60px;
}

.text-center {
    text-align: center !important;
}

.is-hidden {
    display: none;
}

.is-active {
    display: block;
}

a:focus {
    outline: none;
}

button:focus {
    outline: none;
}

.error {
    border: 1px solid  darken(red, 10%) !important;
}

.hidden {
    display: none !important;
}

.error-section {
    width: 500px;
    margin: 0 auto;
    background: $white;
    box-shadow: 0 1px 6px rgba(57,73,76,.35);
    text-align: center;
    padding: 5px 0;

    .red-text {
        margin: 0;
    }
}

.list {
    margin: 0;
    list-style: none;
    padding: 0;
}

@media (max-width: 578px) {
    .account__form .container {
        max-width: 100%;
    }
}

.account {
    height: 100vh;
    overflow: auto;
}

@media (min-width: 1200px) {
    .container {
        max-width: 90% !important;
    }
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.loginNotifier {
    padding: 4px 30px;
    border-radius: 4px;
    margin-top: 10px;
    border: 2px solid #0b88ee;
    color: #0b88ee !important;
    background: transparent;

    &:hover {
        background-color: $blue;
        color: $white !important;
    }
}

.direction-rtl {
    direction: rtl;
}

a {
    text-decoration: none !important;
}

.right-0 {
    right: 0;
}

.left-0 {
    left: 0;
}

.addToFavorites {
    position: absolute;
    bottom: 5px;
    display: flex;
    border: none;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: color ease-in 0.2s;
    color: #7e859b;
    font-size: 13px;
    background-color: #fff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    padding-top: 1px;


    i.fas.fa-heart {
        color: red;
    }
}

.right-5 {
    right: 5px;
}

.left-5 {
    left: 5px;
}

.h-100 {
    height: 100%;
}

.mw-80 {
    max-width: 80%;
    margin: auto;
}


input:disabled,
select:disabled {
    background: transparent !important;
    border-bottom: none !important;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2 !important;
}

.mx-100 {
    max-width: 100% !important;
}

.px-100 {
    padding: 0 100px;
}

.scroll-wrapper {
    max-height: 300px;
    overflow: auto;
    padding: 0 1rem;
}

.line-clamp {
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

// @media (max-width: 800px) {
//     #wrapper {
//         margin-top: 3rem !important;
//         padding-top: 1rem !important;
//     }
// }


.w-70 {
  width: 70px !important;
}

.react-select__control {
  border: 0 !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: 0 !important;
}

.react-select__indicator-separator {
  background: none !important;
}

.mobile-padding{

    @media(max-width: 700px) {
        padding: 0 !important;
    }
}

.sm-alert{
    padding: 4px 8px;
    margin: 0 !important;
}