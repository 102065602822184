.report-container{
    width:100%;

    .report-filter{
        display: flex;
        gap: 8px;
        //width: 220px;
        //margin-inline-start: auto;
        margin: 0 11px;

        .report-datepicker{
            display: flex;
            flex-direction: row;
            gap: 8px;

            @media(max-width: 700px) {
                flex-direction: column;
                gap: 0;
            }
        }

        button {
            //margin: 0 5px;
            width: 60px;
            height: 29px;
            border-radius: 4px;
            //background: transparent;
            //outline: none;
            //border: none;
            font-size: 12px;
        }
    }
}
.report-card{
    text-align: right;
    width: 100% !important;
}
.card{

    a{
        //width: 100%;
        //display: block;
    }

    h6{
        font-weight: bold;
    }

    .product-card{
        height: 90px;
        border: solid 1px rgba(0,0,0,0.2);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 8px;
        border-radius: 5px;
        margin: 16px 0;

        img{
            display: block;
            width: unset;
            height: 70px;
            object-fit: cover;
        }

        .product-info{
            display: flex;
            flex-direction: column;

            span{
                padding: 0 8px;
            }

            .product-name{
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }
    }
}


.online-store__redirect.orange {
    width: 130px;
    min-width: unset;
    text-align: center;

    a{
        width: inherit;
    }
}