.cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.card {
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #d5d5d5;
    background-color: #ffffff;
    margin: 10px;
}

.cards__header {
    padding: 10px; 

    h2 {
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.85;
        letter-spacing: normal;
        color: #707070;
        margin: 0;
    }
}

.card__image {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-height: 100%;
    }
}

.card__slug {
    border-top: 1px solid #ccc;

    h3 {
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.89;
        letter-spacing: normal;
        text-align: center;
        color: #707070;
        margin: 0;
    }
}

@media (max-width: 700px) {

    .card {
        width: 45% !important;
        margin: 5px !important;
    }

    .card__image {
        width: auto !important;
    }
}