.sidebar-filter {
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    width: 22%;
}

.sidebar__wrapper {
    width: auto;
    overflow: auto;
    .widget {
        border-color: rgba( $blue, 0.5);
        &__header,
        &:last-child {
            border-bottom: 1px solid rgba( $blue, 0.5);
        }
        &__header__title {
            letter-spacing: 1.2px;
            text-transform: uppercase;

        }
        &__item label {
            color: rgba( $blue, 0.7);
            letter-spacing: 1.2px;
        }
    }
}

.category-results__main-details {
    width: 75%;
    position: relative;
}

.category-results__main-details__subModels{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.sidebar__mobile__row {
    display: none;
    padding: 5px 20px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,.16);
    z-index: 10000;

}

.sidebar__mobile__row__filters {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    p {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: right;
        color: #707070;
    }

    button {
        width: 70px;
        height: 26px;
        border-radius: 5px;
        background-color: #0b88ee;
        border: 0;
        color: #fff;
        font-size: 14px;
        margin: 0 10px;
    }
}

.sidebar__mobile__row__close {

    button {
        border: 0;
        background: 0;
        font-size: 14px;
        font-weight: bold;
        color: #707070;
    }
}

@media (max-width: 800px) {
    .sidebar-filter {
        position: fixed;
        top: 60px;
        left: 0;
        right: 0;
        width: 100%;
        bottom: 0;
        overflow: auto;
        z-index: 1000;
        background: #fff;

        .sidebar__wrapper {
            padding: 20px;
        }
    }

    .sidebar__mobile__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .category-results__main-details {
        width: 100%;
    }
}

@media (max-width: 992px) {
    .sidebar__filter {
        margin-top: 30px !important;
    }
}
