.filters {
    position: absolute;
    border: solid 1px #cccccc94;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 99;
    background-color: #ffffff;
}

.filters__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.filters__list {
    width: 200px;
    max-height: 400px;
    overflow: auto;
    list-style-type: none;
    margin: 0;

    li {
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.88;
        letter-spacing: normal;
        color: #707070;
        padding: 4px 48px;
        text-align: right;
        border-bottom: 1px solid #cccccc94;
        cursor: pointer;
        
        &:hover {
            background: $blue;
            color: $white;
        }
    }
}

.filters__list--width {
    width: 250px;
}

@media (max-width: 800px) {
    .filters {
        position: fixed;
        top: 90px;
        right: 0;
        left: 0;
        bottom: 0;
        overflow: auto;
    }
}