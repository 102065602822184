#notificationContainer {
    position: absolute;
    top: 50px;
    z-index: 250;
    width: 350px;
    max-height: 568px;
    overflow: auto;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e5e5e5;
    background-color: #ffffff;
}

.notificationsContainer {
    max-height: 400px;
}

.notificationItem {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 8px 12px;
    border-bottom: 1px solid #e5e5e5;
    cursor: pointer;
}

.notificationItem.unread {
    background-color: #edf2fa;
}

.notificationItem:hover {
    background-color: #F7F7F7;
}

.notificationItem .notificationIcon {
    position: relative;

    i {
        color: $blue;
        background-color: #f1f1f1;
        padding: 10px;
        border-radius: 50%;
    }
}

.coloredBell {
    background-color: #f1f1f1;
    padding: 5px;
    border-radius: 50%;
}

.coloredBellBlue {
    background: #f1f1f1;
    color: $blue;
    padding: 5px;
    border-radius: 50%;
}

.notificationItem .notificationBody {
    flex: 1;
    overflow: hidden;
    font-size: 15px;
    color: #333;
}

.notificationItem .notificationHeader, 
.notificationItem .notificationContent {
    margin-bottom: 6px;
}

.notificationItem .notificationFooter {
    overflow: hidden;
}

.notificationItem .name {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #707070;
    margin: 0;
}

.noNotificationsPromise {
    padding: 20px;
}

.AllCaughtUp {
    width: 100;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    i {
        color: $blue;
        background-color: #f1f1f1;
        padding: 5px;
        border-radius: 50%;
        font-size: 24px;
    }
    
    h3 {
        color: #6c757d;
        font-size: 14px;
        font-weight: 500;
        margin: 10px 0 0 0;
    }
}

.timestamp {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 12px;

    p {
        color: #6c757d;
        font-size: 13px;
        margin: 0;
    }
}

.notificationLink {
    display: block;
}

.notificationActions {
    position: relative;
}

.deleteAction {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    font-size: 16px;
    background-color: #ffc107;
    padding: 3px 8px;
    z-index: 9000;
    font-weight: 100;
}

@media (max-width: 785px) {
    #notificationContainer {
        position: fixed;
        top: 55px;
        right: 0;
        left: 0;
        bottom: 0;
        overflow: auto;
        max-height: none;
        max-width: none;
        width: auto;
        border-radius: 0;
        border: 0;
    }

    .notificationsContainer {
        max-height: none;
    }
}
