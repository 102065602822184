.slider {
    position: relative;
    width: 85%;
    margin: 0 auto;
    height: 50px;
}

.rail {
    position: absolute;
    width: 100%;
    height: 10px;
    margin-top: 35px;
    border-radius: 5px;
    background: $light-gray;
}

.handle__value {
    font-size: 11px;
    margin-top: -25px;
}


.filter__price-action {
    display: flex;
    margin-top: 0;
    padding-bottom: 4px;
    flex-direction: column;
    justify-content: flex-end;

    .btn {
        font-size: 13px;
        padding: 3px 12px;
        letter-spacing: .8px;
        width: 100px;
    }

    .filter__price__text {
        color: #7a7d82;
        font-weight: 400;
        font-size: 13px;
    }
}