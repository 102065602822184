.Avatar-wrapper {
    width: 200px;
    max-width: 200px;
}

.avatar {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;   
}

.file-select {
    width: 100%;
    height: 30px;
    background: $blue;
    display: flex;
    justify-content: center;
    border: solid 1px #b8b8b8;
    align-items: center;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;
    color: #fff;
    margin: 10px 0;

    span {
        font-size: 14px;

        i {
            margin: 0 10px;
        }
    }
}

.file-select > input[type="file"] {
    display: none;
}
