.my-account {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    flex: 1;

    .avatar-container{
        width: auto;

        @media(max-width: 700px) {
            width: 100%;
        }

        .Avatar-wrapper{
            margin:0 auto;
        }
    }

    .user-info-container{
        width: auto;

        @media(max-width: 700px) {
            width: 100%;
        }

        .dynamic-padding{
            //padding: auto;

            @media(max-width: 700px) {
                padding: 0 !important;
            }
        }
    }
}

.my-account__actions {

    a, button {
        padding: 2px 20px !important;
        margin: 0 5px !important;
        border-radius: 5px !important; 
        outline: none !important;
        border: none !important;
    }
    
    a {
        background: #cdcdcd;
        color: #333;
    }
    
    button {
        background: $blue;
        color: $white;

        @media(max-width: 700px) {
            margin-bottom: 8px !important;
        }
    }
}

.my-account__actions button.green {
    padding: 2px 50px !important
}