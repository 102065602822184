.carousel .slider-wrapper {
    height: 220px !important;
    border-radius: 10px;
}

.carousel .control-dots {
    text-align: left !important;

    li {
        box-shadow: none !important;
        width: 10px !important;
        height: 10px !important;
        background: $blue !important;
        margin: 0 2px !important;
        opacity: 1 !important;

        &:hover {
            background: $white !important;
        }

        &:focus {
            outline: none !important;
        }
    }
}

.carousel .thumb {
    height: 80px !important;
    border: 2px solid #795548 !important;
}

.carousel .slider-wrapper .slider .slide div a {
    display: block;
    width: 100%;
    height: 220px;

    img {
        max-width: 100%;
        max-height: 100%;
        vertical-align: top;
        border: 0;
    }
}

.category__main__section .hot-deals-carousel {
    margin: 0 !important;
    width: 70%;
}

.hot-deals-carousel {
    width: 70%;
    width: 70%;
    height: 220px;
    border-radius: 10px;
    border: 1px solid #eee;


    &.contrast { 
        background: #eee;

        img {
            -webkit-filter: contrast(.3%);
            filter: contrast(0.3%);
        }
    }
}

.quickmodal__images.categorymodelpage {
    .carousel.carousel-slider {
        width: 100% !important;
    }
}

.quickmodal__images {

    .carousel.carousel-slider {
        width: 50% !important;
        height: 250px;
        margin: 0 auto;
    }

    .carousel .thumbs-wrapper {
        padding-top: 10px;
        margin: 0;

        ul {
            margin: 0;
            padding: 0;

            li {
                opacity: 1;
                cursor: pointer;

                &.selected {
                    opacity: 0.5;
                }
            }
        }
    }
}

.hot-deals-carousel .carousel.carousel-slider .control-arrow {
    color: #9e9e9e;
    width: 35px;
    height: 35px;
    border: solid 0.5px #f8f8f8;
    background-color: #f8f8f8;
    font-size: 20px;
    margin: auto 5px;
    padding: 0;
    opacity: 1;
    border-radius: 50%;

    &.control-prev:before {
        display: inline-block;
        font-family: "Font Awesome 5 Free";
        content: "\f104";
        font-weight: 900;
        border: none;
    }

    &.control-next:before {
        display: inline-block;
        font-family: "Font Awesome 5 Free";
        content: "\f105";
        font-weight: 900;
        border: none;
    }
}

.hot-deals-carousel .carousel.carousel-slider .control-arrow:hover {
    opacity: 0.5;
}
