.footer {
    background: $white;
    color: $blue;
    margin-top: 30px; 
}

.footer__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 200px;
}

.footer__information {
    flex-grow: 2;

    .logo {

        h2 {
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
            margin: 0;
            color: $blue;
        }

    }

    .description {
        padding: 15px 0;

        p {
            max-width: 400px;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 2;
            letter-spacing: normal;
            text-align: right;
            margin: 0;
            color: $blue;
        }
    }

    .footer__socialmedia {

        i {
            font-size: 18px;
            margin: 0 5px;
            cursor: pointer;
        }
    }
}

.footer__static__links {
    flex-grow: 1;

    a {
        color: $blue !important;
        display: block;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: #ffffff;
        margin: 10px 0;

        &:hover {
            color: darken($blue, 10%);
        }
    }
}

.footer__apps{
    display: flex;
    flex-direction: column;
    align-items: center;

    a{
        width: 80%;
    }

    img{
        display: block;
    }
}

.created-by {
    padding: 10px 0;
    border-top: 1px solid #ddd;

    p {
        font-size: 14px;
        line-height: 1.88;
        color: $blue;
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 650px) { 
    .footer__main {
        padding: 20px 0;
        flex-direction: column !important;
        justify-content: flex-start !important;
        min-height: 0;
    }

    .footer__information {
        flex-grow: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 25px;

        .description {
            display: none;
        }
    }

    .footer__static__links {
        flex-grow: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;

        a {
            margin: 2px 0;
        }
    }

}

// Small devices (landscape phones, less than 768px)
@media (max-width: 800px) { }

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { }