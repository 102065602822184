.filter-container{

    @media(max-width: 700px) {
        width: 100%;
    }

    .filter-input{
        width:200px;
        margin:0 8px 0 8px;
        
        @media(max-width: 700px) {
            width: 100%;
            margin:8px 0 8px 0;
        }
    }
}
