.mobileHeader {
    position: relative;
    background: $white;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9999;

    .mobileHeader__inner {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
}

.mobileHeader__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.mobileHeader__user,
.mobileHeader__menu {
    color: $blue;
    display: flex;
    align-items: center;
}

.mobileHeader__user .user,
.mobileHeader__user .notifications,
.mobileHeader__menu .logo,
.mobileHeader__menu .burgerIcon {
    font-size: 22px;
    margin: 0 6px;
    cursor: pointer;
}

.mobileHeader__user .notifications span{
    padding: 5px;
}

.mobileHeader__user .user img {
    width: 22px;
}

.mobileHeader__menu .burgerIcon img {
    height: 26px;
    width: 22px;
}

.mobileHeader__menu .logo img {
    width: 100px;
}

.mobileSearchFilter {
    margin-top: 5px;
}

.mobileSearchFilter__flag {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #9E9E9E;
    padding: 8px 15px;
    border-bottom: 1px solid #d1d1d1;

    i {
        cursor: pointer;
        font-size: 18px;
    }

    span {
        font-size: 14px;
    }
}

.filters__choose {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    letter-spacing: normal;
    color: #707070;
    padding: 2px 15px;
    text-align: right;
    border-bottom: 1px solid hsla(0,0%,80%,.58);

    i {
        direction: ltr;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.9;
        letter-spacing: normal;
        text-align: right;
        color: $blue;
        cursor: pointer;

        span { 
            margin: 0 3px;
        }
    }

    p {
        font-weight: bold;
        margin: 0;
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 800px) { 
    .mobileHeader {
        display: block;
    }
    .header__main__search .searchTooltip {
        border: solid 1px #d1d1d1;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        background-color: rgba(255, 255, 255, 0.17);
        border-radius: 0;
        padding: 0 10px;
    }
    .header__main__search .areas {
        display: none;
    }
    .header__main__search input {
        border: none;
    }

    .header__main__search .mobileFilter {
        font-size: 20px;
        margin: 0 6px;
        cursor: pointer;
    }
    .filters {
        width: 100%;
    }
    .filters__list {
        width: 100% !important;
        height: 100% !important;
        max-height: 100% !important;
    }
}

@media (min-width: 801px) {
    .mobileHeader {
        display: none;
    }
    .mobileSearchFilter {
        display: none;
    }
    .header__main__search .mobileFilter {
        display: none;
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 800px) { }

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { }