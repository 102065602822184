.featuresGroup {
    width: 100%;    
    margin-top: 25px;
}

.featuresGroup__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        flex-grow: 1;
        margin: 0 10px;
        height: 1px;
        opacity: 0.5;
        background-color: $blue;
    }

    a {
        color: #707070;
        font-size: 12px;
        margin: 0 5px;
    }

    h3 {
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.91;
        letter-spacing: normal;
        color: $blue;
    }
}

.slick-slide {
    margin: auto;
}

.slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 15px 0;
}

.slick-dots li {
    margin: 0 5px;

    button {
        width: 10px;
        height: 10px;
        opacity: 0.41;
        border-radius: 50%;
        font-size: 0;
        border: solid 1px #707070;
        background-color: #707070;
    }

    &.slick-active button {
        opacity: 1;
    }
}


@media (max-width: 800px) {
    .featuresGroup__heading {
        justify-content: flex-start;
    
        span {  
            display: none;
        }

        h3 {
            font-size: 14px;
            font-weight: bold;
        }
    }
}