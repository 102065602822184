.gov-name:not(:first-child) {
    margin-top: 50px;
}

.gov-name__header {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.87;
    margin: 0;
    border-bottom: 1px solid $blue;
    color: $blue;
}