.places-frame {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    flex: 0 0 75%;
    max-width: 75%;
    border-radius: 5px;
    margin-bottom: 50px;
    background-color: $white;
    padding: 15px 30px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: 1px solid rgb(238, 238, 238);
}

.places-frame select:disabled {
    opacity: 0.4;
    border: 1px solid rgb(238, 238, 238) !important;
}

.placesOption {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        font-size: 16px;
        line-height: 1.83;
        color: #707070;
        padding: 0 10px;
    }

    select {
        direction: rtl;
        width: 250px;
        border-radius: 5px;
        border: 1px solid rgb(223, 223, 223);      
        background: $white;
        color: #707070;
        padding: 3px;


        &:focus,
        &:active {
          border: 1px solid rgb(223, 223, 223); 
        }
    }

    &:first-of-type {
        flex: 1;
    }
}

@media screen and (max-width: 800px) {
    .places-frame {
        flex: 1;
        max-width: 100%;
        flex-direction: column-reverse;
        padding: 10px;
    }
}