.packages-wrapper {
    text-align: right;
    padding: 25px;
    border: 1px solid #ddd;
}

.package-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.85;
    color: $blue;
    padding: 10px 0;
}

.packages {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    label {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;

        span {
            padding: 0 10px
        }
    }
}


.periods {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.period {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    border: solid 1px #ddd;
    background-color: $white;
    margin: 0 10px;
    cursor: pointer;

    &.selected {
        border: solid 1px $blue;
    }


    strong {
        font-size: 25px;
        line-height: 1.4;
        color: #333;
    }
}