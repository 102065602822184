.header {
    background: $white;
}

.header__main {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header__main__logo {
    flex-grow: 1;

    img {
        height: 45px;
    }
}

.header__main__search {
    position: relative;
    flex-grow: 3;

    .search-form {
        label {
            border: none;
            outline: none;
            padding: 8px 16px;
            &:hover {
                cursor: pointer;
            }
        }
    }

    .searchTooltip {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(11, 136, 238, 0.48);
        background-color: #fff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .areas {
        min-width: 200px;
        padding: 4px 20px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $blue;
        cursor: pointer;

        i {
            color: $blue;
            font-size: 14px;
            margin: 0 5px;
        }
    }

    label {
        color: $blue;
        margin: 0;
        padding: 0 15px;
        font-size: 20px;

        img {
            width: 14px;
        }
    }

    form {
        display: flex;
        flex: 2;
        align-items: center;
    }

    input {
        flex-grow: 2;
        margin: 0;
        padding: 7px 15px;
        border: none;
        outline: none;
        font-size: 14px;
        color: #707070;
        border-right: 1px solid rgba(11, 136, 238, 0.48);
        border-left: 1px solid rgba(11, 136, 238, 0.48);

        &::placeholder {
            opacity: 0.5;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.88;
            letter-spacing: normal;
            color: #707070;
        }

        &:focus {
            outline: none;
        }
    }
}

.header__main__user {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .language {
        display: flex;
        padding: 0 10px;
        cursor: pointer;
        
        span {
            width: 18px;
            height: 18px;
            border-radius: 3px;
            background: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: $blue;
            border: 1px solid $blue;
            font-weight: 100;
            font-style: normal;
            font-stretch: normal;
            line-height: 7.8;
            letter-spacing: normal;
        }      
        
        p {
            margin: 0 0 0 5px;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.35;
            letter-spacing: normal;
            text-align: left;
            color: #707070;
        }
    }   

    .notifications {
        position: relative;
        font-size: 16px;
        color: $blue;
        border-right: 1px solid #ccc;
        border-left: 1px solid #ccc;
        padding: 0 10px;
        cursor: pointer;
        
        i {
            cursor: pointer;
        }
    }

    .user {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;

        &:hover .userOptions {
            display: block;
        }

        i {
            font-size: 16px;
            color: $blue;
        }

        span {
            margin: 0 5px;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.35;
            letter-spacing: normal;
            color: #707070;
            cursor: pointer;

            i {
                margin: 5px;
            }
        }

        ul.userOptions {
            width: 120px;
            position: absolute;
            top: 25px;
            border-radius: 5px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            border: solid 1px #e5e5e5;
            background-color: #ffffff;
            list-style-type: none;
            z-index: 1;
            display: none;

            li {
                font-size: 13px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.88;
                letter-spacing: normal;
                border-bottom: 1px solid #e5e5e5;
                color: #707070;
                padding: 5px;
                cursor: pointer;
                
                i {
                    color: #707070;
                    font-size: 14px;
                }

                a {
                    color: #707070;
                }

                &:hover {
                    background: #eee;
                }
            }
        }
    }
}


// Extra small devices (portrait phones, less than 576px)
@media (max-width: 800px) { 
    .header {
        display: none;
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 800px) { }

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { }

.country-item{
    display: flex;
    align-items: center;
    font-size: 12px;
    gap: 4px;
}

.dopdown-menu-custom-width{
    width: 200px;
}