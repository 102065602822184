.subModel {
    position: relative;
    width: 180px;
    // height: 300px;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #d5d5d5;
    background-color: $white;
    margin: 5px;

    &.w-0 {
        width: 47% !important;
    }

    a {
        display: block;
    }

    &.combo {
        width: 210px;
        max-height: 325px;
        margin: 15px 5px;

        .subModel__avatar {
            width: 100%;
            height: 200px;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.subModel__avatar {
    width: 180px;
    height: 200px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ddd;

    img {
        max-height: 100%;
    }
}

.subModel__details {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px;
 
    h2 {
        font-size: 14px;
        height: 40px;
        line-height: 1.5;
        margin: 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height:40px !important;
        overflow: hidden !important;
        @media (max-width: 800px) { 
            overflow: unset;
        }
        a {
            color: #555555;
        }
    }
}

.subModel__condition {
  width: 50px;
  text-align: center;
  padding: 0 !important;
  margin: 5px 0 !important;
}

.subModel__span {
    display: inline-block;
    // opacity: 0.7;
    font-size: 14px;
    line-height: 1.79;
    color: $white;
    background-color: $dark-orange;
    margin: 8px 4px;
    padding: 4px;
    border-radius: 5px;
    font-weight: 700;
    &.mobile {
        position: absolute;
        bottom: 2px;
        // opacity: 0.5;
    }
}

.subModel__details__price {
    margin: 0;

    .currency, .value {
        line-height: 1.13;
        letter-spacing: normal;
        margin: 0;
    }

    .value {
        color: $blue;
        font-size: 18px;
        font-weight: bold;
    }

    .currency {
        font-size: 14px;
        font-weight: bold;
        margin: 0 3px;
        color: $blue
    }
}


@media (max-width: 900px) {
    .subModel {
        box-shadow: none;
    }
}

.subModel--list {
    width: auto;
    max-width: 80%;
    height: auto;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #cdcdcd;
    background-color: #ffffff;

    &.combo {
        max-width: 100%;
        width: 100%;
        margin: 10px 0;

        .subModel__avatar--border {
            width: 250px !important;
            height: 250px;
            padding: 20px;
            margin: 0;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
        }

        .subModel__details .online-store {
            width: auto;

            &.ar {
                margin-left: auto !important;
            }

            &.en {
                margin-right: auto !important;
            }
        }

        .addToFavorites {
            top: 5px;
        }
    }
}

.subModel__avatar--border {
    border: none;
}

.subModel__details--biggerFont h5 {
    font-size: 20px;
    max-width: 400px;
    font-weight: 600;
    line-height: 1.86;
    color: #707070;
}

.delete__button {
    position: absolute;
    top: 0;
    height: 0;
    font-size: 12px;
    color:#333;
    background: transparent;
    border: none;
    padding: 10px;
}


@media (max-width: 800px) {
    .subModel.combo {
        margin: 10px 3px;
        width: 150px;
        height: 340px;
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px #d5d5d5;
        background-color: #ffffff;

        .subModel__avatar--border {
            margin: 0 5px;
            padding: 0;
        }

        .subModel__avatar {
            width: 100%;
            height: 180px;
            display: flex;
            justify-content: center;
            align-items: center;
    
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .subModel__details h5 {
            font-size: 12px;
            line-height: 1.33;
            overflow: visible !important;
            color: #707070;
        }

        .addToFavorites {
            bottom: 0;
            top: auto;
            bottom: 5px;
            width: 20px;
            height: 20px;
            background: transparent;

            img {
                width: 25px;
            }
        }
    }

    .delete__button {
        bottom: 15px;
        top: auto;
    }

    .subModel--list.combo {
        width: 100% !important;
        height: auto !important;
        padding: 15px 10px !important;

        .subModel__avatar {
            width: 100px !important;
            height: 100px !important;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
            }
        }

        .subModel__details {
            flex: 1;
            height: auto;
            overflow: initial;

            h5 {
                overflow: hidden !important;
            }
        }

        .addToFavorites {
            bottom: 5px;
            margin: 5px;
        }
    }
}