.tools-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

.tools-box__calculations {
    display: flex;
    align-items: center;
    span {
        font-size: 20px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.86;
        letter-spacing: normal;
        color: #707070;
        margin: 0 4px;
    }

    small {
        opacity: 0.64;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.88;
        letter-spacing: normal;
        margin-left: 15px;
        color: #707070;
    }
}

.tools-box__modes {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tools-box__modes__sort {
    display: flex;
    justify-content: center;
    align-items: center;

    label {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #707070;
        margin: 0;
    }

    select {
        border: none;
        font-size: 14px;
        padding: 0;
        color: $blue;
        width: auto;
        cursor: pointer;

        &:focus {
            color: $blue;
            background-color: #fff;
            border-color: none;
            outline: 0;
            box-shadow: none;
        }
    }
}

.box__modes__pipe {
    border: 1px solid #cdcdcd;
    width: 1px;
    height: 25px;
    margin: 0 10px;
    border-width: 1px;
}

.tools-box__modes__list-view {

    i {
        color: #cdcdcd;
        font-size: 18px;
        margin: 0 5px;
        cursor: pointer;

        &:hover {
            color: $blue;
        }

        &.layout__active {
            color: $blue;
        }
    }
}


@media (max-width: 800px) {
    .tools-box {
        display: none;
    }
}