.branches-header {

    button {
        border-radius: 8px;
        padding: 5px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $dark-orange;
        color: #fff;
        font-size: 14px;
        border: none;

        &:hover {
            color: $white;
            background: darken($dark-orange, 5%);
        }
    }

    h3 {
        font-size: 22px;
        line-height: 1.87;
        color: #707070;
    }
}