.branch-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: solid 1px #ddd;
    background-color: #f9f9f9;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    padding: 10px 8px;
    gap: 20px;

    p {
        flex: 1;
        margin: 0;
        line-height: 1.83;
        color: #707070;
        text-align: right;
        min-width: 80px;
    }
}

.branch-list-item__options {
    flex: 1;

    a {
        display: block;
    }

    button{
        margin:0 5px;
    }

    a{
        margin: 0 auto;
    }
    
    button, a {
        //margin: 0 5px;
        width: 60px;
        height: 25px;
        border-radius: 4px;
        background: transparent;
        outline: none;
        border: none;
        font-size: 12px;

        i {
            color: $white;
        }
    }

    .red {
        background: #fb4949;
    }

    .blue {
        background: $blue;
    }

    .darkBlue {
        background: #5058a4;
    }

    .black i {
        color: #333;
    }
}

.button-container{
    display:block;
    justify-content:unset;
    width: 100%;

    @media(max-width: 700px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    button{

        @media(max-width: 700px) {
            padding: 4px 0 !important;
            width: 100%;
        }
    }
}