.branch {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: flex-start;
    border-radius: 5px;
    padding: 10px;
    margin: 15px 0;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #cdcdcd;
    background-color: $white;
}

.branch__logo {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    border: 1px solid #d8d5d5;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        padding: 5px;
    }
}

.branch__details {
    flex: 1;
    padding: 0 10px;
    direction: rtl;
    text-align: right;

    .heading {
        margin-bottom: 1rem;
        
        h4 {
            font-size: 16px;
            color: #707070;
            font-weight: 600;
            margin: 0;
        }
    }
}

.branch__row {

    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;

    i {
        width: 20px;
        height: 20px;
        font-size: 14px;
        background: $blue;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        color: $white;
        margin-left: 10px;
    }

    span {
        font-size: 14px;
        color: #a9a8a8;
        flex: 1;
    }
}

.branch__row.contact {
    span {
        font-size: 14px;
    }
}

.storesIcon {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.89;
    color: #f82e2e;

    i {
        margin: 0 10px;
        font-size: 18px;
    }
}

@media (max-width: 800px) {
    .branch__logo {
        width: 100px;
        height: 100px;
    }
    .mobile-width {
        max-width: 100%;
        padding-top: 0 !important;
    }
    .storesHeader {
        justify-content: center !important;
    }
    .branches {
        width: 100% !important;
    }
    .gov-name:not(:first-child) {
        margin-top: 20px !important;
    }
}