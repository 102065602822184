.model-parent {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px 10px;
    position: relative;
}

.model__name--mobile,
.brand-category--mobile {
    display: none !important;
}

.gallery {
    flex-grow: 1;

    p {
        font-size: 10px;
    }
}


.model {
    flex-grow: 1;
    max-width: 600px;
    padding: 0 20px;
}

.model__name {
    margin-bottom: 15px;
    h1 {
        font-family: 'DroidArabicNaskhBold' ,sans-serif;
        font-weight: bold;
        font-style: normal;
        font-size: 20px;
        line-height: 2;
        color: #555555;
        margin: 0;
    }
}

.model__description {

    p {
        font-size: 14px;
        line-height: 2;
        color: #393939;
        margin: 0;
    }
}

.readmore-description {
    font-weight: bold;
    color: #e5a12b;
    text-decoration: underline;
    cursor: pointer;
}

.model__items-online-stores {
    padding: 20px 0;
}

.model-items-onlne-stores__slug {
    border-bottom: 1px solid $blue;
    padding: 8px 0;

    &.orange {
        border-bottom: 1px solid $dark-orange;

        p {
            color: $dark-orange
        }
    }

    p {
        font-size: 15px;
        font-weight: bold;
        line-height: 1.5;
        color: $blue;
        margin: 0;
    }
}

.model__description__slug {
    border-bottom: 1px solid $blue;
    padding: 8px 0;

    p {
        font-size: 15px;
        font-weight: bold;
        line-height: 1.5;
        color: $blue;
        margin: 0;
    }
}

.model__features {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #dedddd;
}

.model__features__feature-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
}

.model__features__feature-row__feature-name {
    width: 120px;

    span {
        font-size: 14px;
        font-weight: bold;
        line-height: 2.56;
        text-align: right;
        color: #393939;
    }
}

.model__features__feature-row__feature-values {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
        justify-content: center;
        display: flex;
        align-items: center;
        width: 150px;
        border-radius: 8px;
        margin: 5px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px #cdcdcd;
        background-color: #ffffff;
        cursor: pointer;
        font-size: 12px;

        &.selected {
            border: 1px solid $blue;
            color: $blue;
        }
    }
}

.model__features__show-more {
    text-align: right;
    
    p { 
        font-size: 14px;
        line-height: 2.56;
        text-align: right;
        margin: 0;
        color: #db0f0f;
        cursor: pointer;

        i {
            margin: 0 10px;
        }
    }
}

@media (max-width: 800px) {
    .model-parent {
        flex-wrap: wrap;
    }
    .model-ads {
        display: none;
    }

    .model-parent {
        justify-content: center;
    }

   .model {
       padding: 0;
       order: 2;

       .model__name, .brand-category {
           display: none;
       }

       .model__name--mobile {
           display: block !important;
           position: absolute;
           top: 0;
           right: 0;
           left: 0;
           margin: 10px 0;
           padding: 0 20px;
       }

       .model__name--mobile h1 {
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            line-clamp: 2;
            line-height: 2.14;
            -webkit-line-clamp: 2;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

    }
    
    .brand-category--mobile {
        position: absolute;
        top: 50px;
        display: flex !important;
        left: 0;
        right: 0;
        margin: 20px;
        justify-content: space-around !important;
        align-items: center !important;

        span {
            margin: 0 !important;
            font-size: 12px !important;
        }
    }

    .model-images__slider-wrapper__selected {
        height: 250px !important;
    }

    .model-images__slider-wrapper__selected__image {
        height: 200px !important;
        padding: 20px !important;
    }

    .model-images__slider-wrapper__thumbs {
        padding-top: 5px !important;
    }

    .model-images__slider-wrapper__thumbs__thumb {
        width: 50px !important;
        height: 50px !important;
    }

    .online-store.best-price {
        border-top: 1px solid #dedddd;
        margin: 20px 0;
        padding: 20px 0;
        
        p {
            font-size: 14px;
        }
    }

    .online-store__information__price {
        margin: 0 10px;
        font-size: 12px !important;

        .value {
            font-size: 14px !important;
        }
    }

    .online-store__redirect {
        width: 90px !important;
        border-radius: 5px !important;

        a {
            padding: 0 !important;
            font-size: 12px !important
        }
    }

    .online-store__information__avatar {
        width: 50px !important;

        p {
            max-width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 12px !important;
        }
    }

    .model__features {
        padding: 10px 0 !important; 
        justify-content: space-between !important;      
    }

    .model__features__feature-row__feature-values {
        margin: 5px !important;
    }

   .model-images__slider-wrapper {
       margin-bottom: 0 !important;
   }

   .model__features__feature-row {
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }

   .model__features__feature-row__feature-name {
       width: 100%;
   }

   .model__features__feature-row__feature-name span {
       font-size: 12px;
   }

   .model__features__feature-row__feature-values span {
        font-size: 12px;
        border-radius: 6px;
        margin: 0 2px;
        height: 23px;
    }

   .model__items-online-stores {
       padding: 10px 0;
   }

   .model-description {
       padding: 10px 0;
   }

   .model-images {
       order: 1;
       max-width: 100%;
       width: 100%;
       margin-top: 100px;
   }

   .model-images__slider-wrapper__selected {
       width: 100% !important;
   }

   .model-images__slider-wrapper__selected__actions__push_to_favorites, 
   .model-images__slider-wrapper__selected__actions__share {
       font-size: 14px !important;
   }
}

@media (min-width: 801px) and (max-width: 1100px) {
  .model-ads {
      display: none;
  }
}