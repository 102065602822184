.navigation {
    background: $blue;
    position: relative;

    nav {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        height: 40px;

        a,span {
            font-size: 16px;
            font-weight: normal;
            letter-spacing: .36px;
            padding: 0 10px;
            color: #fff;
            display: flex;
            justify-content: center;
            height: 100%;
            align-items: center;

            &.sections:hover {
                background: $white;
                color: $blue;
                margin-top: 3px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            i {
                font-size: 20px;
            }
        }

        .navigation__store {
            background-color: $dark-orange;
            border-right: 2px solid #fff;
            border-left: 2px solid #fff;
            height: 100%;
            justify-content: center;
            display: flex;
            font-size: 14px;
            align-items: center;
            width: 130px;

            i {
                margin: 0 5px;
                font-size: 14px;
            }
        }
    }
}

.flayers {
    height: 100%
}

.navigation__storeLink {
    flex: 1
}

.navigation nav .flayers:hover .navigation__section__categories {
    display: flex;
    justify-content: center;
}

.navigation__section__categories {
    display: none;
    position: absolute;
    top: 40px;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9999;
    background: #00000047;
}

.navigation__section__categories__content {
    width: 800px;
    height: 300px;
    overflow-y: auto;
    background: $white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.28);
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;

    p {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 0;
        line-height: 1.83;
        letter-spacing: 0.36px;
        color: #707070;
    }

    ul {
        list-style-type: none;


        li {
            font-size: 16px;
            line-height: 1.83;
            letter-spacing: 0.36px;
            color: #707070;
        }
    }
}

.flayers-image {
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-height: 100%;
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { }

// Small devices (landscape phones, less than 768px)
@media (max-width: 800px) { 
    .navigation {
        display: none;
    }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 800px) and (max-width: 920px) { 
    .navigation nav a,span {
        font-size: 14px;
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { }