.gallery-images {
    width: 250px;
}

.image {
    position: relative;
    border-radius: 5px;
    border: solid 1px #ddd;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-images__selected {
  height: 250px;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid #dedddd;
}

.image--big {
    width: 100%;
    height: 150px;
    overflow: hidden;

    img {
        max-width: 100%;
        max-height: 100%;
    }

    &:hover .image__options {
        display: block;
    }
}

.image--small {
    width: 80px;
    height: 50px;
    margin-top: 10px;

    img {
        width: 30px;
    }

    &:hover .image__options {
        display: block;
    }
}

.image__options {
  position: absolute;
    width: auto;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: white;
    opacity: 1;
    text-align: center;
    z-index: 1;
    display: none;
    
    .image__options--overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

        span {
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .file-select {
            width: auto;
            background: $white;
            color: $blue;
            box-shadow: none;
            border: 0;      
            margin: 5px 0;
    
            &:hover {
                background: darken($white, 5%);
            }
        }
    }
}

.upload-progress {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    background: #eeeeeeb5;
    align-items: center;

    span {
        font-size: 25px;
        color: $white;
        font-weight: bold;
    }
}