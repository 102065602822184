.mobile-menu {
    position: fixed;
    visibility: hidden;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: auto;
    z-index: 999;
    background: $white;
    top: 47px;
    transform: translateX(-310px);
    transition: all .3s ease-in-out;

    &.show {
        visibility: visible;
        transform: translateX(0px);
        transition: all .3s ease-in-out;
    }
}

.mobile-menu__modules {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0;
}

.mobile-menu__module {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mobile-menu__module__box {
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    font-size: 18px;
    border-radius: 5px;
    background-color: $blue;
}

.mobile-menu__module__heading {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: $blue;
}

.mobile-menu__sections__header a {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobile-menu__sections__header h3 {
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    color: #e5a12b;
}

.mobile-menu__sections__header i {
    color: #e5a12b;
}

.mobile-menu__sections__list {
    list-style-type: none;
    margin: 0;

    li a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        border-top: 1px solid #ccc;
        color: #707070;
        padding: 8px 0;
    }
}

.mobile-menu__logout {
    display: flex;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    border-top: 1px solid #ccc;
    color: #707070;
    padding: 5px 0;
}

.mobile-menu__logout__title {
    margin: 0 5px;
    font-weight: bold;

    p {
        margin: 0;
    }
}