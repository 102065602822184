.auth {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 50px auto;
    width: 75%;
    min-height: 400px;
    border-radius: 30px;
    box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.17);
    border: solid 1px #d5d5d5;
    background-color: #ffffff;
    padding: 40px 0;
}

.auth__form {
    max-width: 500px;

    form {
        width: 100%;
        margin: 20px 0;
    
        label {
            font-size: 14px;
            line-height: 1.85;
            color: #707070;
            margin: 0;
    
            input, select {
                margin: 0 10px;
            }
        }
        
        input, select {
            padding: 0;
            margin-top: 10px;
            border-bottom: 1px solid #ccc;
            border-top: none;
            border-left: none;
            border-right: none;
            color: #8c8a8a;
        }
    
        input:focus, 
        select:focus {
            border-bottom: 1px solid $blue;
            outline: none;
        }
    
        input::placeholder {
            font-size: 12px;
            font-weight: 300;
            line-height: 1.86;
            color: $light-gray;
        }
    
        button {
            border-radius: 5px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            border: solid 1px $light-gray;
            background-color: $blue;
            padding: 10px 45px;
    
            &:hover {
                background: darken($blue, 5%);
            }

            &.gray {
                background: $light-gray;
                color: $white;
            }

            &:disabled {
                background: $light-gray;
            }
        }
    }
}

.user__actions__forgot {
    font-size: 12px;
    font-weight: 100;
}

.auth__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .vertical {
        width: 8px;
        height: 25px;
        border-radius: 7px;
        background-color: #0b88ee;
    }

    h2 {
        font-size: 30px;
        font-weight: bold;
        line-height: 1.88;
        color: #0b88ee;
        margin: 0 25px;
    }

    .horizontial {
        width: 100px;
        height: 4px;
        border-radius: 5px;
        background-color: #0b88ee;
    }
}

.auth__message {
    p {
        font-size: 14px;
        line-height: 1.85;
        color: #707070;
        margin: 0;

        max-width: 350px;
        padding: 30px 0;
    }
}

.auth__image {
    width: 300px;

    &.updatePassword {
        width: 200px;
    }
}


// Extra small devices (portrait phones, less than 576px)
@media (max-width: 650px) { 
    .auth {
        flex-direction: column !important;
        margin: 0;
        width: 100%;
        min-height: 400px;
        padding: 20px;
        border-radius: none;
        box-shadow: none;
        border: none;
        background-color: #fff;

        .auth__form {
            order: 2;
            width: 100%;
            padding-top: 30px;

            .auth__header h2 {
                font-size: 18px;
            }

            form {

                .submitForm {
                    flex-direction: column !important;

                    button {
                        width: 100%;
                    }

                    p {
                        margin-top: 10px;
                    }
                }
            }
        }

        .auth__image {
            width: 250px;
            order: 1;

            &.updatePassword {
                width: 200px;
            }
        }
    }
}


// Small devices (landscape phones, less than 768px)
@media (min-width: 651px) and (max-width: 930px) { 
    .auth {
        width: 100%;
        min-height: 400px;
        padding: 20px;
        border-radius: none;
        box-shadow: none;
        border: none;
        background-color: #fff;
    }
 }

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { }