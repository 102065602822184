// Colors
$blue: #0b88ee;
$dark-blue: #0038A8;
$gray: #F4F4F4;
$off-gray: #a5afd7;
$light-gray:#b8b8b8;
$off-white: #e5e5e5;
$white: #ffffff;
$dark-gray: #454545;
$yellow: #FFE11B;
$off-black: #333;
$dark-orange: #e5a12b;
$red: #db0f0f;

// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;
