.handler {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
}

.handler__flag {
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-height: 100%;
    }
}

.handler__flag--emptySet {
    width: 100px !important;
    height: 100px;
    border-radius: 50%;
    background-color: #eee;

    i {
        font-size: 50px;
    }
}

.handler__message {
    margin: 20px 0;

    p {
        margin: 0;
        font-size: 25px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.87;
        text-transform: uppercase;
        letter-spacing: normal;
        text-align: center;
        color: #707070;
    }
}

.handler__message--search-text {
    p {
        text-transform: lowercase;
        font-size: 20px;
        font-weight: normal;
    }
}

.handler__message--emptySet {
    margin: 25px 0;

    p {
        font-size: 25px;
    }

    span {
        font-size: 20px;
        font-weight: 700;
        color: #aaa;
        letter-spacing: 0.2px;
    }
}

.handler__backHome {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 40px;
    border-radius: 61px;
    background-color: #a2dbf1;

    a {
        color: $white;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: bold;
    }

    &:hover {
        background: darken(#a2dbf1, 5%);
    }
}

@media (max-width: 800px) {
    .handler__flag {
        width: 300px;
    }
}