.Switches-container{
    position: static;
    width: auto;
    top: 0;
    right: 0;

    @media(max-width: 700px) {
        position: absolute;
        width: 100%;
        top: -34px;
        right: -2px;
    }
}

.Switches::-webkit-scrollbar {
    display: none;
}

.Switches {
    position: absolute;
    top: -34px;
    right: -2px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 700px) {
        position: relative !important;
        top:0 !important;
        right:0 !important;
        justify-content: normal !important;
        align-items: center !important;
        //display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
}

.Switch {
    width: 180px;
    padding: 3px;
    background: #f3f3f3;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid #ddd;
    @media(max-width: 700px) {
        flex: 0 0 auto;
    }
}

.Switch.active {
    background-color: $white;
    border-bottom: 0;
}

.Switch span {
    font-size: 14px;
    line-height: 1.85;
    color: #707070;
}

.Switch.active span {
    color: $blue;
}