.section__ads {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 30px 0;
}

@media (max-width: 980px) {
    .section__ads--mobileVersion {
        display: none;
    }
}