.Breadcrumb {
    background: $light-gray;

    p {
        color: $dark-gray;
        padding: 15px;
        font-size: 18px;
        margin: 0;

        span {
            font-weight: bold;
        }
    }
}

.searchresults {

    .searchresults__insider {
        margin: 20px 0;
        padding: 15px;

        .resultrelated__tag {
            margin-bottom: 8px;
            display: block;
            border-bottom: 1px solid #e5e5e5;
    
            h5 { 
                font-weight: 700;
                display: inline-block;
                margin-bottom: 0;
                line-height: 40px;
                position: relative;
                text-transform: uppercase;
                font-size: 16px;
                border-bottom: 2px solid #0b88ee;
                color: #454545;
            }
        }
    }
}

.match {
    display: flex !important;
    margin: 10px 0;
    box-shadow: 0.9px 1.8px 8px 0 rgba(0, 0, 0, 0.19);

    .match__image {
        position: relative;
        width: 100px;
        height: 100px;
        z-index: 1;
    
        .match__thumb {
            position: relative;
            // display: block;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            overflow: hidden;
    
            img {
                opacity: 1;
                visibility: visible;
                -webkit-transition: all .4s ease-out 0s;
                -o-transition: all .4s ease-out 0s;
                transition: all .4s ease-out 0s;
                width: 100%;
                height: 100%;
            }
        }
    }

    .match__details {
        padding: 15px 0 0 15px;
        width: 100%;
    }

    .match__details--title {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        word-break: break-word;
        margin: 0;

        a {
            color: #454545;
        }
    }
}
