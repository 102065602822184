.pagination {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;

    li {
        display: inline-block;
        list-style: none;
        margin: 0 2px;

        a {
            font-weight: 400;
            color: #fff;
            padding: 0 10px;
            height: 30px;
            line-height: 30px;
            background: #ccc;
            border-radius: 8px;
            font-size: 14px;
            display: inline-block;
            cursor: pointer;
        }
    }
}

.pagination li.active a {
    background: $blue;   
}